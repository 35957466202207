<template>
    <div class="period-picker">
        <hx-input
            class="picker-input"
            readonly
            :clearButton="true"
            :placeholder="placeholder"
            v-model="pickerLegend"
            @click="onPickerClick"
            @blur="onPickerBlur"
            @clear="onPickerClear"
        />
        <div
            class="picker-menu"
            v-show="menuVisible"
        >
            <div class="picker-date picker-date-start">
                <div class="picker-date-legend">{{ dateStartLegend }}</div>
                <Datepicker
                    class="date-picker"
                    :inline="true"
                    v-model="values.dateStart"
                    :highlighted="highlighted"
                    :value="values.dateStart"
                    :disabled-dates="{
                        from: values.dateEnd
                    }"
                    :language="language"
                    @selected="onDateStartChange"
                ></Datepicker>
            </div>
            <div class="picker-date picker-date-end">
                <div class="picker-date-legend">{{ dateEndLegend }}</div>
                <Datepicker
                    class="date-picker"
                    :inline="true"
                    v-model="values.dateEnd"
                    :highlighted="highlighted"
                    :value="values.dateEnd"
                    :disabled-dates="{
                        to: values.dateStart
                    }"
                    :language="language"
                    @selected="onDateEndChange"
                ></Datepicker>
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment'

import Datepicker from 'vuejs-datepicker'

export default {
    name: 'PeriodPicker',
    components: {
        Datepicker
    },
    props: {
        placeholder: {
            type: String,
            default: ''
        },
        language: {
            type: Object,
            default: () => {}
        },
        dateStartLegend: {
            type: String,
            default: 'Date start:'
        },
        dateEndLegend: {
            type: String,
            default: 'Date end:'
        },
        dateStart: {
            type: Date,
            default: null
        },
        dateEnd: {
            type: Date,
            default: null
        },
        legendPredicate: {
            type: Function,
            default: new Date()
        }
    },
    data () {
        return {
            pickerLegend: null,
            menuVisible: false,
            highlighted: {},
            values: {
                dateStart: this.dateStart,
                dateEnd: this.dateEnd
            }
        }
    },
    methods: {
        onPickerClick (e) {
            this.menuVisible = true
            const today = new Date()
            if (!this.values.dateStart) {
                this.values.dateStart = today
                this.highlighted.from = today
            }
            if (!this.values.dateEnd) {
                this.values.dateEnd = today
                this.highlighted.to = today
            }
        },
        onPickerBlur (e) {
            this.menuVisible = false
        },
        onPickerClear () {
            this.menuVisible = false
            this.reset()
            this.sendPeriodChange()
        },
        reset () {
            this.pickerLegend = null
            this.values.dateStart = null
            this.values.dateEnd = null
            this.highlighted.from = null
            this.highlighted.to = null
        },
        onDateStartChange (date) {
            this.values.dateStart = date
            if (moment(this.values.dateStart).isAfter(this.values.dateEnd)) {
                this.values.dateEnd = this.values.dateStart
            }
            this.updateHighlight()
            this.updateLegend()
            this.sendPeriodChange()
        },
        onDateEndChange (date) {
            this.values.dateEnd = date
            this.updateHighlight()
            this.updateLegend()
            this.sendPeriodChange()
        },
        updateHighlight () {
            this.highlighted.from = this.values.dateStart
            this.highlighted.to = this.values.dateEnd
        },
        getDefaultLegend () {
            return 'Period'
        },
        updateLegend () {
            if (this.legendPredicate) {
                this.pickerLegend = this.legendPredicate(
                    this.values.dateStart,
                    this.values.dateEnd
                )
                return
            }
            this.pickerLegend = this.getDefaultLegend()
        },
        sendPeriodChange () {
            this.$emit('change', {
                ...this.values
            })
        }
    },
    watch: {
        dateStart (val) {
            if (!val) {
                this.reset()
            }
        },
        dateEnd (val) {
            if (!val) {
                this.reset()
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import '~@happytal/bo-ui-library/src/styles/variables';

.period-picker {
    position: relative;

    .picker-input {
        width: 100%;
    }
    .picker-menu {
        position: absolute;
        display: flex;
        z-index: 4;
        right: 0px;
        top: 55px;
        padding: 20px 20px 20px 20px;
        background: #FFFFFF;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
        border-radius: 8px;

        @media (max-width: map-get($breakpoints, sm)) {
            flex-wrap: wrap;
            left: calc((100% - 340px) / 2);
            right: auto;
            top: 43px;
        }
        @media (max-width: map-get($breakpoints, lg)) {
            flex-wrap: wrap;
            left: calc((100% - 340px) / 2);
            right: auto;
            top: 43px;
        }

        .picker-date {
            flex-grow: 1;

            .picker-date-legend {
                margin: 0px 0px 10px 0px;
                font-weight: 600;
                font-size: 14px;
                line-height: 14px;
                color: #2C2C2C;
            }
            .date-picker {

                .highlighted {
                    background: rgba(var(--vs-primary), 0.2);
                    color: #2C2C2C;
                }
            }
        }
        .picker-date-start {

        }
        .picker-date-end {
            margin: 0px 0px 0px 20px;

            @media (max-width: map-get($breakpoints, sm)) {
                margin: 0px 0px 0px 0px;
            }
            @media (max-width: map-get($breakpoints, lg)) {
                margin: 0px 0px 0px 0px;
            }

            .picker-date-legend {

                @media (max-width: map-get($breakpoints, sm)) {
                    margin: 10px 0px 10px 0px;
                }
                @media (max-width: map-get($breakpoints, lg)) {
                    margin: 10px 0px 10px 0px;
                }
            }
        }
    }
}
</style>
