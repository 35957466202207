<template>
    <HPopin
        :value="value"
        @input="close"
        width="small"
        class="suggestion-overview-popin"
    >
        <template #header>
            {{ suggestionTitleLabel }}
        </template>
        <div class="overview">
            <div class="subscription">{{ subscriptionLabel }}</div>
            <div class="content">
                <div class="avatar">
                    <img class="image" :src="productImageUrl"/>
                </div>
                <div
                    class="description"
                    v-html="descriptionHtml"
                />
                <div class="quantity">
                    <div class="legend">{{ quantityLabel }}</div>
                    <div class="price-total">{{ priceLabel }}</div>
                </div>
            </div>
            <div class="sent-by">{{ sentByLabel }}</div>
        </div>
    </HPopin>
</template>

<script>
    import _ from 'lodash'
    import moment from 'moment'

    import Formats from '@/constants/formats'

    import {
        getFrequencyLabel,
        getSuggestionTitleLabel,
        getSuggestionOptionLabel,
        getSuggestionDescriptionLabel,
        getSuggestionTypeLabel,
        getResidentNameLabel
    } from '@/modules/labels'
    import {
        getSuggestionQuantity,
        getSuggestionPrice,
        getSuggestionFrequency,
        getSuggestionImageUrl
    } from '@/modules/suggestions'
    import {
        formatLocalDate
    } from '@/modules/date'

    import {
        HPopin
    } from "@happytal/bo-ui-library"

    export default {
        name: 'SuggestionOverviewPopin',
        model: {
            prop: 'value',
            event: 'input'
        },
        components: {
            HPopin
        },
        props: {
            value: {
                type: Boolean,
                default: false
            },
            suggestion: {
                type: Object
            },
            productOffers: {
                type: Array
            },
            variantData: {
                type: Array
            },
            serviceModelServices: {
                type: Array
            }
        },
        computed: {
            suggestionTitleLabel () {
                return getSuggestionTitleLabel(this.suggestion)
            },
            subscriptionLabel () {
                if (_.get(this.suggestion, 'suggestionOpen', null)) {
                    return ''
                }
                const type = _.get(this.suggestion, 'orderType', null)
                const typeLabel = getSuggestionTypeLabel(type)
                const frequency = getSuggestionFrequency(this.suggestion)
                const frequencyLabel = getFrequencyLabel(frequency)
                return type == 'OneShot' ? `${typeLabel}` : `${typeLabel} : ${frequencyLabel}`
            },
            quantityLabel () {
                if (_.get(this.suggestion, 'suggestionOpen', null)) {
                    return ''
                }
                const quantity = getSuggestionQuantity(this.suggestion)
                const price = getSuggestionPrice(this.suggestion)
                return `${quantity} x ${price}€`
            },
            priceLabel () {
                if (_.get(this.suggestion, 'suggestionOpen', null)) {
                    return ''
                }
                const quantity = getSuggestionQuantity(this.suggestion)
                const price = getSuggestionPrice(this.suggestion)
                const amount = (quantity * price).toFixed(2)
                return `${amount}€`
            },
            productImageUrl () {
                return getSuggestionImageUrl(this.suggestion, this.serviceModelServices)
            },
            descriptionHtml () {
                return getSuggestionOptionLabel(this.suggestion, this.productOffers, this.variantData, this.serviceModelServices)
            },
            sentByLabel () {
                const senderLabel = _.get(this.suggestion, 'createdBy.name', null)
                const createdOnUtc = _.get(this.suggestion, 'createdOnUtc', null)
                const dateLabel = formatLocalDate(createdOnUtc, Formats.DisplayDate)
                return `Envoyé par ${senderLabel} le ${dateLabel}`
            }
        },
        methods: {
            close () {
                this.$emit('input', false)
            }
        }
    }
</script>

<style lang="scss" scoped>
    .suggestion-overview-popin::v-deep {

        .overview {

            .subscription {
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 15px;
                text-align: right;
                color: #606060;
            }
            .content {
                display: flex;
                align-items: center;

                .avatar {
                    width: 54px;
                    height: 54px;

                    .image {
                        object-fit: cover;
                        height: 100%;
                    }
                }
                .description {
                    flex-grow: 1;
                    align-self: flex-start;
                    margin: 0px 10px 0px 10px;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 10px;
                    line-height: 15px;
                    color: #000000;
                }
                .quantity {

                    .legend {
                        font-style: normal;
                        font-weight: 500;
                        font-size: 10px;
                        line-height: 15px;
                        text-align: right;
                        color: #000000;
                    }
                    .price-total {
                        font-style: normal;
                        font-weight: 700;
                        font-size: 24px;
                        line-height: 20px;
                        text-align: right;
                        color: rgba(var(--vs-primary), 1);
                    }
                }
            }
            .sent-by {
                margin: 10px 0px 0px 0px;
                font-style: normal;
                font-weight: 400;
                font-size: 11px;
                line-height: 10px;
                text-align: right;
                color: #C4C4C4;
            }
        }
    }
</style>
