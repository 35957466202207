<template>
    <div class="suggestions-dashboard">
        <DeleteSuggestionPopin
            v-model="deleteSuggestionPopinVisible"
            :suggestion="suggestionToDelete"
            @cancel="onDeleteSuggestionPopinCancel"
            @validate="onDeleteSuggestionPopinValidate"
        />
        <SuggestionOverviewPopin
            v-model="suggestionOverviewPopinVisible"
            :suggestion="suggestionToView"
            :productOffers="productOffers"
            :variantData="variantData"
            :serviceModelServices="serviceModelServices"
        />
        <SuggestionSideBar
            v-model="suggestionSideBarVisible"
            :suggestion="suggestionToView"
        />

        <SuggestionFilterBar
            class="filter-bar"
            :filters="filters"
            @change="onFilterChange"
        />

        <div
            v-if="items.length > 0"
            class="stats"
        >
            <div
                v-if="chartLoaded"
                class="graphs"
            >
                <SuggestionDoughnutChart
                    class="chart status-chart"
                    :chartData="charts.status.data"
                    :options="charts.status.options"
                />
                <SuggestionDoughnutChart
                    class="chart type-chart"
                    :chartData="charts.type.data"
                    :options="charts.type.options"
                />
                <div class="global">
                    <div class="number">{{ stats.totalCount }}</div>
                    <div class="legend">{{ stats.totalCount > 1 ? 'suggestions' : 'suggestion' }}</div>
                </div>
            </div>
        </div>

        <div
            v-if="items.length > 0"
            class="menu-actions"
        >
            <div class="sort-legend">Trier par</div>
            <HSelect
                class="sort-select"
                placeholder="Sélectionner un tri"
                v-model="sortBy"
                :options="sorts"
                @input="onSortChange"
                searchable
                label="label"
                track-by="label"
                data-cy="sort-select"
            />
            <div
                v-if="sortBy"
                class="direction-legend"
            >Ordre</div>
            <HSelect
                v-if="sortBy"
                class="direction-select"
                placeholder="Sélectionner un ordre"
                v-model="directionBy"
                :options="directions"
                @input="onDirectionChange"
                searchable
                label="label"
                track-by="label"
                data-cy="direction-select"
            />
        </div>

        <div
            class="no-suggestions"
            v-if="loaded && items.length < 1"
        >
            Aucun résultat ne correspond à votre recherche.
        </div>
        <div
            class="suggestions"
            v-else
        >
            <HCard
                type="free"
                class="suggestion"
                :class="getItemStyle(item)"
                v-for="(item, itemIndex) in items"
                :key="itemIndex"
            >
                <div class="main">
                    <div class="status">{{ getSuggestionStatusLabel(item.suggestion.status) }}</div>
                    <div class="content">
                        <div class="column resident-column">
                            <div class="column-title">Résident</div>
                            <div class="column-name resident-name">{{ getResidentNameLabel(item.resident) }}</div>
                        </div>
                        <div class="product product-column">
                            <div class="avatar" v-if="!isSuggestionOpen(item)">
                                <img class="image" :src="getSuggestionProductImageUrl(item)"/>
                            </div>
                            <div class="informations">
                                <div class="column-title">{{ getSuggestionSubscriptionLabel(item.suggestion) }}</div>
                                <div class="column-name product-name">{{ getSuggestionProductLabel(item) }}</div>
                                <a
                                    class="detail-link"
                                    @click="onSeeDetailBtnClick(item)"
                                >Voir le détail</a>
                            </div>
                        </div>
                        <div
                            v-if="item.user"
                            class="column referant-column"
                        >
                            <div class="column-title">Référent</div>
                            <div class="column-name referant-name">{{ getReferantNameLabel(item.user) }}</div>
                            <div class="column-legend" v-html="getReferantLegend(item.user)"></div>
                        </div>
                        <div
                            v-else
                            class="column referant-column"
                        >
                            <div class="column-title">Référent</div>
                            <HTooltip
                                left
                                color="primary"
                                :max-width="150"
                            >
                                <template #activator="{ on, attrs }">
                                    <span
                                        v-bind="attrs"
                                        v-on="on"
                                    >
                                        <HIcon
                                            class="primary--text"
                                            icon-name="AlertCircleIcon"
                                            size="32"
                                        />
                                    </span>
                                </template>
                                Il n’y a pas de référent pour ce résident.
                            </HTooltip>
                            <div class="column-legend">
                                <router-link
                                    class="info-link"
                                    :to="getResidentRoute(item)"
                                >Associer un référent</router-link>
                            </div>
                        </div>
                        <div class="column expiration-column">
                            <div class="column-title">Expire dans</div>
                            <div
                                class="column-name days"
                                v-html="getSuggestionExpirationDateHtml(item.suggestion)"
                            ></div>
                        </div>
                        <div class="column message-column">
                            <div class="column-title">E-mail envoyé</div>
                            <div class="column-name message-date">{{ getSuggestionCommentDateLabel(item) }}</div>
                        </div>
                        <div class="column comment-column">
                            <div
                                v-if="hasComment(item)"
                                class="comment-btn"
                                data-cy="comment-btn"
                                @click="onCommentBtnClick(item)"
                            >
                                <HIcon
                                    class="primary--text"
                                    icon-name="MessageCircleIcon"
                                    size="32"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="actions">
                    <HBtn
                        v-if="isDeleteEnabled(item)"
                        icon-only
                        icon="TrashIcon"
                        size="small"
                        class="delete-btn"
                        data-cy="delete-btn"
                        @click="onDeleteBtnClick(item)"
                    ></HBtn>
                </div>
            </HCard>
        </div>

        <HPagination
            class="pagination"
            v-if="pageCount > 1"
            v-model="pageCurrent"
            :length="pageCount"
            :total-visible="9"
            circle
            @input="onPageChange"
        />
    </div>
</template>

<script>
import qs from 'qs'
import _ from 'lodash'
import moment from 'moment'

import Http from '@/http'

import Formats from '@/constants/formats'
import Routes from '@/constants/routes'
import SuggestionStatus from '@/constants/suggestion-status'
import SuggestionTypes from '@/constants/suggestion-types'

import {
    getSuggestionTitleLabel,
    getSuggestionStatusLabel,
    getSuggestionTypeLabel,
    getFrequencyLabel,
    getResidentNameLabel
} from '@/modules/labels'
import {
    getSuggestionFrequency,
    getSuggestionImageUrl,
    getSuggestionStatusColor
} from '@/modules/suggestions'
import {
    formatLocalDate
} from '@/modules/date'

import SuggestionFilterBar from './components/SuggestionFilterBar'
import {
    HBtn,
    HCard,
    HSelect,
    HIcon,
    HTooltip,
    HPagination
} from '@happytal/bo-ui-library'
import SuggestionDoughnutChart from './components/SuggestionDoughnutChart'
import DeleteSuggestionPopin from '@/views/residents/suggestions/components/DeleteSuggestionPopin'
import SuggestionOverviewPopin from './components/SuggestionOverviewPopin'
import SuggestionSideBar from './components/SuggestionSideBar'

export default {
    name: 'SuggestionDashboard',
    components: {
        SuggestionFilterBar,
        HBtn,
        HCard,
        HSelect,
        HIcon,
        HTooltip,
        HPagination,
        SuggestionDoughnutChart,
        DeleteSuggestionPopin,
        SuggestionOverviewPopin,
        SuggestionSideBar
    },
    data () {
        return {
            loaded: false,
            chartLoaded: false,
            deleteSuggestionPopinVisible: false,
            suggestionToDelete: null,
            suggestionOverviewPopinVisible: false,
            suggestionToView: null,
            suggestionSideBarVisible: false,
            filters: {},
            pageCurrent: 1,
            pageCount: 1,
            itemsPerPage: 6,
            stats: {},
            items: [],
            productOffers: null,
            variantData: null,
            serviceModelServices: null,
            sortBy: null,
            sorts: [
                { id: 'CreatedOnUtc', label: 'Date de création' },
                { id: 'Resident', label: 'Résident' },
                { id: 'User', label: 'Référent' }
            ],
            directionBy: null,
            directions: [],
            statuses: [
                SuggestionStatus.PENDING,
                SuggestionStatus.ALREADYFULLFILLED,
                SuggestionStatus.FULLFILLED,
                SuggestionStatus.FULLFILLEDEXTERNALY,
                SuggestionStatus.REFUSED,
                SuggestionStatus.EXPIRED
            ],
            types: [
                SuggestionTypes.RECURRING,
                SuggestionTypes.ONESHOT,
                SuggestionTypes.OPEN
            ],

            charts: {
                status: {
                    data: {
                        labels: [],
                        datasets: [
                            {
                                label: 'Dataset 1',
                                data: [],
                                backgroundColor: []
                            }
                        ]
                    },
                    options: {
                        responsive: true,
                        legend: {
                            position: 'top',
                            labels: {
                                fontSize: 14,
                                fontFamily: 'Montserrat,serif',
                                color: '#606060'
                            },
                            onClick: function (e, legendItem) {}
                        },
                        plugins: {
                            datalabels: {
                                display: true,
                                align: 'center',
                                color: 'white',
                                font: {
                                    family: 'Montserrat,serif',
                                    weight: 600,
                                    size: 14
                                }
                            },
                            doughnutlabel: {
                                labels: [
                                    {
                                        text: 'Statut',
                                        color: 'black',
                                        font: {
                                            family: 'Montserrat,serif',
                                            size: 26,
                                            weight: 600
                                        }
                                    }
                                ]
                            }
                        },
                        onClick: (e, array) => this.onChartClick('status', array)
                    }
                },
                type: {
                    data: {
                        labels: [],
                        datasets: [
                            {
                                label: 'Dataset 1',
                                data: [],
                                backgroundColor: []
                            }
                        ]
                    },
                    options: {
                        responsive: true,
                        legend: {
                            position: 'top',
                            labels: {
                                fontSize: 14,
                                fontFamily: 'Montserrat,serif',
                                color: '#606060'
                            },
                            onClick: function (e, legendItem) {}
                        },
                        plugins: {
                            datalabels: {
                                display: true,
                                align: 'center',
                                color: 'white',
                                font: {
                                    family: 'Montserrat,serif',
                                    weight: 600,
                                    size: 14
                                }
                            },
                            doughnutlabel: {
                                labels: [
                                    {
                                        text: 'Type',
                                        color: 'black',
                                        font: {
                                            family: 'Montserrat,serif',
                                            size: 26,
                                            weight: 600
                                        }
                                    }
                                ]
                            }
                        },
                        onClick: (e, array) => this.onChartClick('type', array)
                    }
                }
            }
        }
    },
    methods: {
        getUserId () {
            return this.$store.getters['dataUser/getUserId']()
        },
        getInstitutionId () {
            return this.$store.getters['dataUser/getInstitutionId']()
        },
        onFilterChange (params) {
            //console.log('params', params)
            this.filters = params.filters
            this.pageCurrent = 1
            this.chartLoaded = false
            this.searchSuggestions()
        },
        onChartClick (type, array) {
            if (array.length < 1) {
                return
            }
            const position = array[0]._index
            switch (type) {
                case 'status':
                    //const status = this.statuses[position]
                    //this.status = [status]
                    //this.searchSuggestions()
                    break
                case 'type':
                    break
            }
        },
        onSortChange (sortBy) {
            this.sortBy = sortBy
            this.updateSortDirections()
            this.searchSuggestions()
        },
        updateSortDirections () {
            const sortBy = _.get(this.sortBy, 'id', null)
            if (!sortBy) {
                this.directionBy = null
                return
            }
            switch (sortBy) {
                case 'CreatedOnUtc':
                    this.directions = [
                        { id: 'Asc', label: 'Plus ancienne à plus récente' },
                        { id: 'Desc', label: 'Plus récente à plus ancienne' }
                    ]
                    break
                case 'Resident':
                case 'User':
                    this.directions = [
                        { id: 'Asc', label: 'De A à Z' },
                        { id: 'Desc', label: 'De Z à A' }
                    ]
                    break
            }
            this.directionBy = this.directions[0]
        },
        onDirectionChange (directionBy) {
            this.directionBy = directionBy
            this.searchSuggestions()
        },
        onSeeDetailBtnClick (item) {
            const suggestion = _.get(item, 'suggestion', {})
            this.suggestionToView = suggestion
            this.suggestionOverviewPopinVisible = true
        },
        onCommentBtnClick (item) {
            const suggestion = _.get(item, 'suggestion', {})
            this.suggestionToView = suggestion
            this.suggestionSideBarVisible = true
        },
        hasComment (item) {
            const comment = _.get(item, 'suggestion.processedComment', null)
            if (comment) {
                return true
            }
            return false
        },
        isDeleteEnabled (item) {
            const suggestion = _.get(item, 'suggestion', {})
            return suggestion.status == SuggestionStatus.PENDING
        },
        onDeleteBtnClick (item) {
            const suggestion = _.get(item, 'suggestion', {})
            const suggestionId = suggestion.id
            const residentId = _.get(item, 'resident.id')
            this.suggestionToDelete = {
                residentId,
                suggestionId,
                title: getSuggestionTitleLabel(suggestion)
            }
            this.deleteSuggestionPopinVisible = true
        },
        onDeleteSuggestionPopinCancel () {

        },
        onDeleteSuggestionPopinValidate () {
            this.deleteSuggestion(this.suggestionToDelete)
        },
        async deleteSuggestion (suggestion) {
            const payload = {
                residentId: _.get(suggestion, 'residentId'),
                suggestionId: _.get(suggestion, 'suggestionId')
            }
            try {
                await this.$store.dispatch(
                    'dataSuggestions/deleteSuggestion',
                    payload
                )
                this.searchSuggestions()
            }
            catch (error) {
                this.showErrorToast(`Une erreur s'est produite (${error}).`)
            }
        },
        getItemStyle (item) {
            const suggestion = _.get(item, 'suggestion', {})
            switch (suggestion.status) {
                case SuggestionStatus.PENDING: return 'suggestion-pending'
                case SuggestionStatus.ALREADYFULLFILLED: return 'suggestion-already-fullfilled'
                case SuggestionStatus.FULLFILLED: return 'suggestion-fullfilled'
                case SuggestionStatus.FULLFILLEDEXTERNALY: return 'suggestion-fullfilled-externaly'
                case SuggestionStatus.REFUSED: return 'suggestion-refused'
                case SuggestionStatus.EXPIRED: return 'suggestion-expired'
            }
        },
        isSuggestionOpen (item) {
            return _.get(item, 'suggestion.suggestionOpen', null) != null
        },
        getSuggestionProductImageUrl (item) {
            return getSuggestionImageUrl(item.suggestion, this.serviceModelServices)
        },
        getSuggestionProductLabel (item) {
            return getSuggestionTitleLabel(item.suggestion)
        },
        getSuggestionSubscriptionLabel (suggestion) {
            if (_.get(suggestion, 'suggestionOpen', null)) {
                return 'Suggestion libre'
            }
            const type = _.get(suggestion, 'orderType', null)
            const typeLabel = getSuggestionTypeLabel(type)
            const frequency = getSuggestionFrequency(suggestion)
            const frequencyLabel = getFrequencyLabel(frequency)
            return type == 'OneShot' ? `${typeLabel}` : `${typeLabel} : ${frequencyLabel}`
        },
        getSuggestionExpirationDateHtml (suggestion) {
            const expireOnUtc = _.get(suggestion, 'expireOnUtc', null)
            if (!expireOnUtc) {
                return '-'
            }
            const date = moment.utc(expireOnUtc).local()
            const today = moment()
            if (date.isAfter(today)) {
                const days = date.diff(today, 'days')
                return days > 0 ? `${days} jour` + (days > 1 ? 's' : '') : `Aujourd'hui`
            }
            else {
                const dateLegend = date.format(Formats.DisplayDate)
                return `Expirée le<br />${dateLegend}`
            }
        },
        getSuggestionCommentDateLabel (item) {
            const date = _.get(item, 'lastNotifiedOnUtc', null)
            if (!date) {
                return '-'
            }
            moment.locale('fr')
            const dateLegend = formatLocalDate(date, `DD MMM YYYY`)
            return `le ${dateLegend}`
        },
        getResidentNameLabel (resident) {
            return getResidentNameLabel(resident)
        },
        getReferantNameLabel (resident) {
            return getResidentNameLabel(resident)
        },
        getReferantLegend (referant) {
            const email = _.get(referant, 'email', null)
            const emailLink = email ? `<a class="info-link" href="mailto:${email}">${email}</a>` : ''
            const phone = _.get(referant, 'phoneNumber', null)
            const phoneLink = phone ? ` - <a class="info-link" href="tel:${phone}">${phone}</a>` : ''
            return `${emailLink}${phoneLink}`
        },
        getResidentRoute (item) {
            const residentId = _.get(item, 'resident.id', null)
            return {
                name: Routes.ResidentProfile,
                params: {
                    resident_id: residentId
                }
            }
        },
        getStatusChartData (source) {
            const values = []
            const labels = []
            const colors = []
            this.statuses.forEach((status, index) => {
                const value = _.get(source, `statistics.statuses.${status}`, 0)
                if (value > 0) {
                    values.push(value)
                    labels.push(this.getSuggestionStatusLabel(status))
                    colors.push(this.getSuggestionStatusColor(status))
                }
            })
            return {
                values,
                labels,
                colors
            }
        },
        getTypeChartData (source) {
            const values = []
            const labels = []
            const colors = []
            this.types.forEach((type, index) => {
                const value = _.get(source, `statistics.types.${type}`, 0)
                if (value > 0) {
                    values.push(value)
                    labels.push(getSuggestionTypeLabel(type))
                    colors.push(this.getSuggestionTypeColor(type))
                }
            })
            return {
                values,
                labels,
                colors
            }
        },
        getSuggestionStatusColor (status) {
            return getSuggestionStatusColor(status)
        },
        getSuggestionStatusLabel (status) {
            return getSuggestionStatusLabel(status)
        },
        getSuggestionTypeColor (type) {
            switch (type) {
                case SuggestionTypes.RECURRING: return '#33ABA1'
                case SuggestionTypes.ONESHOT: return '#78D9D1'
                case SuggestionTypes.OPEN: return '#61C378'
            }
        },
        onPageChange (page) {
            this.pageCurrent = page
            this.searchSuggestions()
        },
        searchSuggestions () {
            this.loaded = false
            this.$vs.loading()
            this.suggestions = []
            let searchParams = {
                InstitutionId: this.getInstitutionId(),
                PageIndex: this.pageCurrent,
                PageSize: this.itemsPerPage,
                CurrentUserId: this.getUserId()
            }
            const directionBy = _.get(this.directionBy, 'id', null)
            if (directionBy) {
                searchParams.SortDirection = directionBy
            }
            const dateStart = _.get(this.filters, 'period.dateStart', null)
            if (dateStart) {
                searchParams.CreatedOnUtcFrom = formatLocalDate(dateStart, Formats.RequestDate)
            }
            const dateEnd = _.get(this.filters, 'period.dateEnd', null)
            if (dateEnd) {
                searchParams.CreatedOnUtcTo = formatLocalDate(dateEnd, Formats.RequestDate)
            }
            const residents = _.get(this.filters, 'residents', null)
            if (residents) {
                searchParams.ResidentIds = residents
            }
            const referants = _.get(this.filters, 'referants', null)
            if (referants) {
                searchParams.UserIds = referants
            }
            const statuses = _.get(this.filters, 'statuses', null)
            if (statuses) {
                searchParams.Statuses = statuses
            }
            const sortBy = _.get(this.sortBy, 'id', null)
            if (sortBy) {
                searchParams.SortBy = sortBy
            }
            Http.apis.ehpad.get(`/suggestions/dashboard`, {
                params: searchParams,
                paramsSerializer: (params) => {
                    return qs.stringify(params, {
                        arrayFormat: 'repeat'
                    })
                }
            })
            .then((response) => {
                const data = response.data || {}
                this.items = data.suggestions
                this.stats = {
                    totalCount: _.get(data, 'pagination.totalCount', 0)
                }
                this.pageCount = _.get(data, 'pagination.totalPages', 0)

                const statusDataset = this.getStatusChartData(data)
                this.charts.status.data.labels = statusDataset.labels
                this.charts.status.data.datasets[0].data = statusDataset.values
                this.charts.status.data.datasets[0].backgroundColor = statusDataset.colors

                const typeDataset = this.getTypeChartData(data)
                this.charts.type.data.labels = typeDataset.labels
                this.charts.type.data.datasets[0].data = typeDataset.values
                this.charts.type.data.datasets[0].backgroundColor = typeDataset.colors

                this.productOffers = data.productOffers
                this.variantData = data.variantData
                this.serviceModelServices = data.serviceModelServices
                this.$vs.loading.close()
                this.loaded = true
                this.chartLoaded = true
            })
            .catch((error) => {
                this.$vs.loading.close()
                this.showErrorToast(`Une erreur s'est produite (${error}).`)
            })
        },
        showErrorToast(text) {
            this.$toasted.show(text, {
                theme: 'hx-toasted',
                position: 'hx-bottom-left',
                duration: null,
                action: [
                    {
                        text: 'Fermer',
                        onClick: (e, to) => {
                            to.goAway(0)
                        }
                    }
                ]
            })
        }
    },
    mounted () {
        this.sortBy = this.sorts[0]
        this.updateSortDirections()
        this.searchSuggestions()
    }
}
</script>

<style lang="scss" scoped>
@import '~@happytal/bo-ui-library/src/styles/variables';

.suggestion-dashboard-view {

}

::v-deep .info-link {
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    text-decoration: underline;
}

.suggestions-dashboard {

    .filter-bar {

    }
    .stats {
        margin: 25px 0px 0px 0px;

        @media (max-width: map-get($breakpoints, sm)) {
            height: 700px;
        }
        @media (min-width: map-get($breakpoints, sm)) {
            height: 300px;
        }
        @media (min-width: map-get($breakpoints, lg)) {
            height: 400px;
        }

        .graphs {
            display: flex;
            align-items: center;
            justify-content: center;

            @media (max-width: map-get($breakpoints, sm)) {
                flex-wrap: wrap;
            }

            .chart {
                width: 400px;

                @media (max-width: map-get($breakpoints, sm)) {
                    width: 300px;
                }
                @media (max-width: map-get($breakpoints, lg)) {
                    width: 300px;
                }
            }
            .status-chart {

            }
            .type-chart {

                @media (max-width: map-get($breakpoints, sm)) {
                    margin: 25px 0px 0px 0px;
                }
                @media (min-width: map-get($breakpoints, lg)) {
                    margin: 0px 0px 0px 100px;
                }
            }
            .global {
                padding: 10px 15px 10px 15px;
                border-radius: 8px;
                background: #FFFFFF;
                box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.12);
                text-align: center;
                font-style: normal;
                color: #1596AF;

                @media (max-width: map-get($breakpoints, sm)) {
                    margin: 25px 0px 0px 0px;
                }

                .number {
                    font-weight: 600;
                    font-size: 20px;
                    line-height: 16px;
                }
                .legend {
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 14px;
                }
            }
        }
    }
    .menu-actions {
        display: flex;
        align-items: center;
        margin: 25px 0px 0px 0px;

        @media (max-width: map-get($breakpoints, sm)) {
            justify-content: center;
            flex-wrap: wrap;
        }

        .sort-legend {
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 18px;
            color: #2C2C2C;
        }
        .sort-select {
            width: 240px;
            margin: 0px 0px 0px 10px;

            ::v-deep .multiselect--active {
                z-index: 2;
            }
        }
        .direction-legend {
            margin: 0px 0px 0px 10px;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 18px;
            color: #2C2C2C;

            @media (max-width: map-get($breakpoints, sm)) {
                width: 60px;
                margin: 20px 0px 0px 0px;
                text-align: right;
            }
        }
        .direction-select {
            width: 240px;
            margin: 0px 0px 0px 10px;

            @media (max-width: map-get($breakpoints, sm)) {
                margin: 20px 0px 0px 10px;
            }

            ::v-deep .multiselect--active {
                z-index: 2;
            }
        }
    }
    .no-suggestions {
        margin: 30px 0px 30px 0px;
        text-align: center;
    }
    .suggestions {

        .suggestion {
            display: flex;
            align-items: center;
            margin: 22px 0px 0px 0px;

            .main {
                flex-grow: 1;

                .status {
                    margin: 2px 0px 0px 5px;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 12px;
                    line-height: 15px;
                }
                .content {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 5px 10px 15px 25px;

                    @media (max-width: map-get($breakpoints, sm)) {
                        flex-wrap: wrap;
                    }

                    .column {

                    }
                    .column-title {
                        font-style: normal;
                        font-weight: 400;
                        font-size: 10px;
                        line-height: 12px;
                        color: #BFBFBF;
                    }
                    .column-name {
                        font-style: normal;
                        font-weight: 600;
                        font-size: 14px;
                        line-height: 19px;
                        color: #2C2C2C;
                    }
                    .column-legend {

                    }
                    .product {
                        display: flex;
                        align-items: center;

                        .avatar {
                            width: 43px;
                            height: 43px;

                            .image {
                                object-fit: cover;
                                height: 100%;
                            }
                        }
                        .informations {
                            margin: 0px 0px 0px 11px;

                            .product-name {
                                margin: 0px 20px 0px 0px;
                            }
                            .detail-link {
                                font-style: normal;
                                font-weight: 500;
                                font-size: 9px;
                                line-height: 15px;
                                text-decoration: underline;
                            }
                        }
                    }
                    .resident-name {

                    }
                    .referant-name {

                    }
                    .days {
                        flex-shrink: 0;
                        width: 150px;
                    }
                    .message-date {

                    }
                    .comment-btn {
                        cursor: pointer;
                    }
                    .resident-column {
                        flex-shrink: 0;
                        width: 200px;

                        @media (max-width: map-get($breakpoints, sm)) {
                            width: 100%;
                        }
                        @media (max-width: map-get($breakpoints, lg)) {
                            width: 100px;
                        }
                    }
                    .product-column {
                        flex-grow: 1;
                        margin: 0px 20px 0px 0px;

                        @media (max-width: map-get($breakpoints, sm)) {
                            width: 100%;
                            margin: 10px 20px 0px 0px;
                        }
                        @media (max-width: map-get($breakpoints, lg)) {
                            width: 100px;
                        }
                    }
                    .referant-column {
                        flex-shrink: 0;
                        width: 250px;
                        margin: 0px 20px 0px 0px;

                        @media (max-width: map-get($breakpoints, sm)) {
                            width: 100%;
                            margin: 0px 0px 10px 0px;
                        }
                        @media (max-width: map-get($breakpoints, lg)) {
                            width: 150px;
                        }
                    }
                    .expiration-column {
                        flex-shrink: 0;
                        width: 100px;
                    }
                    .message-column {
                        flex-shrink: 0;
                        width: 120px;
                    }
                    .comment-column {
                        flex-shrink: 0;
                        width: 40px;

                        @media (max-width: map-get($breakpoints, lg)) {
                            width: 35px;
                        }
                    }
                }
            }
            .actions {
                flex-shrink: 0;
                width: 50px;

                .delete-btn {
                    margin: 0px 0px 0px 5px;
                }
            }
        }
        .suggestion-pending {
            border-left: 11px solid #33ABA1;

            .status {
                color: #33ABA1;
            }
        }
        .suggestion-already-fullfilled {
            border-left: 11px solid #78D9D1;

            .status {
                color: #78D9D1;
            }
        }
        .suggestion-fullfilled {
            border-left: 11px solid #61C378;

            .status {
                color: #61C378;
            }
        }
        .suggestion-fullfilled-externaly {
            border-left: 11px solid #438753;

            .status {
                color: #438753;
            }
        }
        .suggestion-refused {
            border-left: 11px solid #F2994A;

            .status {
                color: #F2994A;
            }
        }
        .suggestion-expired {
            border-left: 11px solid #F3811F;

            .status {
                color: #F3811F;
            }
        }
    }
    .pagination {
        margin: 25px 0px 0px 0px;
    }
}
</style>
