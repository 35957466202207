<template>
    <HSideBar
        class="suggestion-sidebar"
        :active="value"
        @update:active="close"
    >
      <template #header>
        Commentaire
      </template>
      <template v-if="suggestion" #default>
        <div class="content text-d">
          <p class="my-4">
            Envoyé
            <template v-if="suggestion.processedBy && suggestion.processedBy.firstName && suggestion.processedBy.lastName">
              par <strong>{{ suggestion.processedBy.firstName }} {{ suggestion.processedBy.lastName }}</strong>
              <br />
            </template>
            le
            <strong>{{ commentDateLabel }}</strong>
          </p>
          <div class="sidebar-divider"></div>
          <h4 class="tw-font-bold text-d mt-6 mb-4">
            {{ suggestionTitle }}
          </h4>
          <div
            class="suggestion-status text-center py-2 mb-8"
            :style="{
              backgroundColor: statusColor,
            }"
          >
            <span class="label">{{ statusLabel }}</span>
          </div>
          <p class="tw-font-bold mb-2">
            Précision de la réponse pour le personnel de l’EHPAD
          </p>
          <p>
            {{ suggestion.processedComment }}
          </p>
        </div>
      </template>
    </HSideBar>
</template>

<script>
import _ from 'lodash'
import moment from 'moment'

import Formats from '@/constants/formats'

import {
    getSuggestionTitleLabel,
    getSuggestionStatusLabel
} from '@/modules/labels'
import {
    getSuggestionStatusColor
} from '@/modules/suggestions'
import {
    formatLocalDate
} from '@/modules/date'

import {
    HSideBar
} from '@happytal/bo-ui-library'

export default {
    name: 'SuggestionSideBar',
    model: {
        prop: 'value',
        event: 'input'
    },
    components: {
        HSideBar
    },
    props: {
        value: {
            type: Boolean,
            default: false
        },
        suggestion: {
            type: Object
        }
    },
    computed: {
        suggestionTitle () {
            return getSuggestionTitleLabel(this.suggestion)
        },
        commentDateLabel () {
            const processedOnUtc = _.get(this.suggestion, 'processedOnUtc', null)
            return formatLocalDate(processedOnUtc, Formats.DisplayDate)
        },
        statusColor () {
            const status = _.get(this.suggestion, 'status', null)
            return getSuggestionStatusColor(status)
        },
        statusLabel () {
            const status = _.get(this.suggestion, 'status', null)
            return getSuggestionStatusLabel(status)
        }
    },
    methods: {
        close () {
            this.$emit('input', false)
        }
    }
}
</script>

<style lang="scss" scoped>
@import '~@happytal/bo-ui-library/src/styles/variables';

.suggestion-sidebar {

    .suggestion-status {
        padding: 0.5rem 1rem;
        background-color: #28c76f;
        color: white;
        font-weight: 600;
        text-align: center;
        font-size: 12px;
        letter-spacing: .5px;

        .label {
            text-transform: uppercase;
            margin: .2rem 0;
        }
    }
}
</style>
