<template>
    <div class="suggestion-filter-bar">
        <div class="actions">
            <div class="title">Filtrer par :</div>
            <div class="filters">
                <div class="filter resident-search">
                    <HSelect
                        class="resident-select"
                        placeholder="Sélectionner un résident"
                        v-model="resident"
                        :options="residents"
                        @input="onResidentSearch"
                        autocomplete
                        searchable
                        multiple
                        label="label"
                        track-by="label"
                        data-cy="resident-select"
                    />
                </div>
                <div class="filter status-search">
                    <HSelect
                        class="status-select"
                        placeholder="Sélectionner un statut"
                        v-model="status"
                        :options="statuses"
                        @input="onStatutSearch"
                        :max-elements="3"
                        multiple
                        searchable
                        label="label"
                        track-by="label"
                        data-cy="status-select"
                    />
                </div>
                <PeriodPicker
                    class="filter date-search"
                    placeholder="Sélectionner une période"
                    :language="datePickerLocale"
                    dateStartLegend="Date de début :"
                    dateEndLegend="Date de fin :"
                    :legendPredicate="legendPredicate"
                    :dateStart="period ? period.dateStart : null"
                    :dateEnd="period ? period.dateEnd : null"
                    @change="onPeriodChange"
                    data-cy="date-picker"
                />
                <div class="filter referant-search">
                    <HSelect
                        class="referant-select"
                        placeholder="Sélectionner un référent"
                        v-model="referant"
                        :options="referants"
                        @input="onReferantSearch"
                        searchable
                        multiple
                        label="label"
                        track-by="label"
                        data-cy="referant-select"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import qs from 'qs'
import _ from 'lodash'
import moment from 'moment'
import {
    fr
} from 'vuejs-datepicker/dist/locale'

import Http from '@/http'
import {
    getRequestUrl
} from '@/modules/url'
import {
    getSuggestionStatusLabel,
    getResidentNameLabel
} from '@/modules/labels'
import {
    formatLocalDate
} from '@/modules/date'

import Formats from '@/constants/formats'
import Roles from '@/constants/roles'
import SuggestionStatus from '@/constants/suggestion-status'

import {
    HSelect
} from '@happytal/bo-ui-library'
import PeriodPicker from '@/components/PeriodPicker'

export default {
    name: 'SuggestionFilterBar',
    components: {
        PeriodPicker,
        HSelect
    },
    props: {
        filters: {
            type: Object,
            required: true
        }
    },
    data () {
        return {
            datePickerLocale: fr,
            resident: null,
            residents: [],
            referant: null,
            referants: [],
            status: null,
            statuses: [],
            period: null
        }
    },
    methods: {
        getUserId () {
            return this.$store.getters['dataUser/getUserId']()
        },
        getInstitutionId () {
            return this.$store.getters['dataUser/getInstitutionId']()
        },
        legendPredicate (dateStart, dateEnd) {
            var legend = ''
            var dateStartText = dateStart ? formatLocalDate(dateStart, Formats.SimpleDate) : ''
            var dateEndText = dateEnd ? formatLocalDate(dateEnd, Formats.SimpleDate) : ''
            if (dateStart && dateEnd) {
                legend = `Du ${dateStartText} au ${dateEndText}`
            }
            else if (dateStart) {
                legend = dateStartText
            }
            else if (dateEnd) {
                legend = `Avant le ${dateEndText}`
            }
            return legend
        },
        onPeriodChange (value) {
            //console.log('dateStart', value.dateStart)
            //console.log('dateEnd', value.dateEnd)
            this.period = value
            this.sendChange('period')
        },
        sendChange (name) {
            const {
                resident,
                status,
                referant,
                period
            } = this
            this.$emit('change', {
                name,
                filters: {
                    residents: _.map(resident, (r) => {
                        return r.id
                    }),
                    statuses: _.map(status, (s) => {
                        return s.id
                    }),
                    referants: _.map(referant, (r) => {
                        return r.id
                    }),
                    period
                }
            })
        },
        onOrderClear () {
            this.order = null
        },
        onResidentSearch (residents) {
            this.resident = residents
            this.sendChange('residents')
        },
        searchResidents (search) {
            var searchParams = {
                institutionId: this.getInstitutionId()
            }
            const userId = this.getUserId()
            Http.apis.ehpad.get(`/users/${userId}/residents`, {
                params: searchParams,
                paramsSerializer: (params) => {
                    return qs.stringify(params, {
                        arrayFormat: 'repeat'
                    })
                }
            })
            .then((response) => {
                const data = response.data || {}
                const residents = data.residents || []
                this.residents = _.map(residents, (resident, index) => {
                    return {
                        ...resident,
                        label: getResidentNameLabel(resident, false)
                    }
                })
            })
            .catch((error) => {
                this.showErrorToast(`Une erreur s'est produite (${error}).`)
            })
        },
        onStatutSearch (statuses) {
            this.status = statuses
            this.sendChange('statuses')
        },
        onReferantSearch (referants) {
            this.referant = referants
            this.sendChange('referants')
        },
        searchReferants (search) {
            let searchParams = {
                application: 'happydom',
                InstitutionId: this.getInstitutionId(),
                UserTypes: [
                    Roles.Tutor,
                    Roles.Family
                ]
            }
            Http.apis.ehpad.get(`/users`, {
                params: searchParams,
                paramsSerializer: (params) => {
                    return qs.stringify(params, {
                        arrayFormat: 'repeat'
                    })
                }
            })
            .then((response) => {
                const data = response.data || {}
                const tutors = data.users || []
                this.referants = _.map(tutors, (tutor, index) => {
                    return {
                        ...tutor,
                        label: getResidentNameLabel(tutor, false)
                    }
                })
            })
            .catch((error) => {
                this.showErrorToast(`Une erreur s'est produite (${error}).`)
            })
        },
        updateStatuses () {
            this.statuses = _.map([
                    SuggestionStatus.PENDING,
                    SuggestionStatus.ALREADYFULLFILLED,
                    SuggestionStatus.FULLFILLED,
                    SuggestionStatus.FULLFILLEDEXTERNALY,
                    SuggestionStatus.REFUSED,
                    SuggestionStatus.EXPIRED
                ],
                (status) => {
                    return {
                        id: status,
                        label: getSuggestionStatusLabel(status)
                    }
                }
            )
        },
        showErrorToast (text) {
            this.$toasted.show(text, {
                theme: 'hx-toasted',
                position: 'hx-bottom-left',
                duration: null,
                action: [
                    {
                        text: 'Fermer',
                        onClick: (e, to) => {
                            to.goAway(0)
                        }
                    }
                ]
            })
        }
    },
    mounted () {
        this.searchResidents()
        this.searchReferants()
        this.updateStatuses()
    }
}
</script>

<style lang="scss" scoped>
@import '~@happytal/bo-ui-library/src/styles/variables';

.suggestion-filter-bar {
    padding: 20px 20px 20px 20px;
    background: #FFFFFF;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.135216);
    border-radius: 8px;

    .actions {

        .title {
            margin: 0px 0px 13px 0px;
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            line-height: 22px;
            color: #2C2C2C;
        }
        .filters {
            display: flex;
            align-items: center;

            @media (max-width: map-get($breakpoints, sm)) {
                flex-wrap: wrap;
            }

            .filter {
                width: calc((100% - 60px) / 4);
                flex-shrink: 0;
                margin: 0px 20px 0px 0px;

                @media (max-width: map-get($breakpoints, sm)) {
                    width: 100%;
                    margin: 10px 20px 0px 0px;
                }

                .resident-select {

                    ::v-deep .multiselect--active {
                        z-index: 2;
                    }
                }
                .status-select {

                    ::v-deep .multiselect--active {
                        z-index: 2;
                    }
                }
                .referant-select {

                    ::v-deep .multiselect--active {
                        z-index: 2;
                    }
                }
            }
            .filter:last-child {
                margin: 0px 0px 0px 0px;

                @media (max-width: map-get($breakpoints, sm)) {
                    margin: 10px 20px 0px 0px;
                }
            }
            .date-search {

                ::v-deep .vs-input--input {
                    border-radius: 8px;
                    border: 1px solid #343a40 !important;
                }
            }
        }
    }
}
</style>
