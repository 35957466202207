<script>
import { Doughnut, mixins } from 'vue-chartjs'
import DataLabels from 'chartjs-plugin-datalabels'
import Doughnutlabel from 'chartjs-plugin-doughnutlabel'
const { reactiveProp } = mixins

export default {
    extends: Doughnut,
    mixins: [
        reactiveProp
    ],
    props: [
        'chartData',
        'options'
    ],
    mounted () {
        this.addPlugin(DataLabels)
        this.addPlugin(Doughnutlabel)
        this.renderChart(this.chartData, this.options)
    }
}
</script>
