import _ from 'lodash'

import SuggestionStatus from '@/constants/suggestion-status'

function getSuggestionQuantity (suggestion) {
    return _.get(suggestion, 'suggestionProduct.quantity', 0) ||
    _.get(suggestion, 'suggestionService.quantity', 0)
}
function getSuggestionPrice (suggestion) {
    const suggestionService = _.get(suggestion, 'suggestionService', null)
    if (suggestionService) {
      const options = suggestionService.options || []
      return suggestionService.unitPrice +
          _.sumBy(options, option => {
              const values = option.values || []
              return option.unitPrice + _.sumBy(values, value => value.unitPrice)
          })
    }
    return _.get(suggestion, 'suggestionProduct.unitPrice', 0)
}
function getSuggestionFrequency (suggestion) {
    return _.get(suggestion, 'suggestionProduct.frequencyInMonth', 0) ||
    _.get(suggestion, 'suggestionService.frequencyInMonth', 0)
}
function getSuggestionImageUrl (suggestion, serviceModelServices) {
    if (!suggestion) {
        return null
    }
    if (suggestion.suggestionService) {
        const serviceModelService = serviceModelServices.find(sms => sms.services.find(s => s.code === _.get(suggestion, 'suggestionService.code', null)))
        if (serviceModelService) {
            return _.get(serviceModelService, 'serviceModel.publicData.imageUrl', null)
        }
    }
    return _.get(suggestion, 'suggestionProduct.imageUrl', null) ||
    _.get(suggestion, 'suggestionService.imageUrl', null)
}
function getSuggestionStatusColor (status) {
    switch (status) {
        case SuggestionStatus.PENDING: return '#33ABA1'
        case SuggestionStatus.ALREADYFULLFILLED: return '#78D9D1'
        case SuggestionStatus.FULLFILLED: return '#61C378'
        case SuggestionStatus.FULLFILLEDEXTERNALY: return '#438753'
        case SuggestionStatus.REFUSED: return '#F2994A'
        case SuggestionStatus.EXPIRED: return '#F3811F'
    }
}

export {
    getSuggestionQuantity,
    getSuggestionPrice,
    getSuggestionFrequency,
    getSuggestionImageUrl,
    getSuggestionStatusColor
}
